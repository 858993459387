export const items = [
  // {
  //   title: "Favourites",
  //   icon: 'mdi-heart',
  //   group: 'pages',
  //   children: [
  //     {
  //       title: 'My Favourite Page',
  //       icon: 'mdi-food-fork-drink',
  //       to: 'blank'
  //     },
  //     {
  //       title: 'jag test comp dash',
  //       icon: 'mdi-monitor-dashboard',
  //       to: 'companydashboard'
  //     }
  //   ]
  // },
  {
    title: "Support",
    expanded: true,
    icon: "mdi-details",
    group: "pages",
    children: [
      {
        title: "Companies",
        icon: "mdi-format-list-bulleted-type",
        to: "companies",
        metaData: {

          scopes: ['Company.Read']

        }
      },
    ]
  },
  {
    title: 'Products',
    icon: 'mdi-package-variant-closed',
    group: 'pages',
    metaData: {
      scopes: ['ProductSkeleton.Read']
    },
    children: [
      {
        title: 'Product Skeletons',
        icon: 'mdi-package-variant',
        to: "productskeletons",
        metaData: {
          scopes: ['ProductSkeleton.Read']
        }
      },

    ]
  },
  {
    title: 'Sales',
    icon: 'mdi-sale',
    group: 'pages',
    metaData: {
      scopes: ['Commission.Read']
    },
    children: [
      {
        title: 'Company Commissions',
        icon: 'mdi-cash-register',
        to: 'companycommissions',
        metaData: {
          scopes: [
            'Commission.Approver'
          ]
        }
      },
      {
        title: 'My Commissions',
        icon: 'mdi-cash-multiple',
        to: 'mycommission',
        metaData: {
          scopes: ['Commission.Read']
        }
      },
      {
        title: 'Add Commission',
        icon: 'mdi-cash',
        to: 'addcommission',
        metaData: {
          scopes: ['Commission.Create']
        }
      }
    ]
  },
  {
    title: 'Users',
    icon: 'mdi-account-multiple',
    group: 'pages',
    metaData:
    {
      scopes: ['User.Modify']
    }
    ,
    children: [
      {
        title: 'System Users',
        icon: 'mdi-account',
        to: "systemusers",
        metaData:
        {
          scopes: ['User.Read']
        }

      },
      {
        title: 'Groups',
        icon: 'mdi-account-group-outline',
        to: 'groups',
        metaData:
        {
          scopes: ['Scope.Modify']
        }

      },
      {
        title: 'Scopes',
        icon: 'mdi-key',
        to: 'scopes',
        metaData:
        {
          scopes: ['Scope.Modify']
        }

      }
    ]
  },
  {
    title: 'Reports',
    icon: 'mdi-chart-bar-stacked',
    group: 'pages',
    metaData:
    {
      scopes: ['Report.Read']
    }
    ,
    children: [
      {
        icon: 'mdi-chart-box-outline',
        title: 'Reports',
        to: 'reports',
        metaData:
        {
          scopes: ['Report.Read']
        }

      }
    ]
  },
  // {
  //   title: 'Testing',
  //   icon: 'mdi-rocket',
  //   group: 'pages',
  //   children: [
  //     {
  //       icon: 'mdi-plus',
  //       title: 'Add Job',
  //       to: 'addjob'
  //     }
  //   ]
  // }
  // {
  //   title: 'Colin',
  //   icon: 'mdi-folder-star-multiple',
  //   group: 'colin',
  //   children: [
  //     {
  //       title: 'Companies',
  //       icon: 'mdi-gold',
  //       to: 'companies'
  //     }
  //   ]
  // },
  // {
  //   title: 'Dev',
  //   icon: 'mdi-folder-star-multiple',
  //   group: 'pages',
  //   children: [
  //     {
  //       icon: 'mdi-email-seal-outline',
  //       title: 'Token Management',
  //       to: 'tokenmanagement'
  //     },
  //     {
  //       icon: 'mdi-car-turbocharger',
  //       title: 'Data Export',
  //       to: 'export'
  //     },
  //     {
  //       title: 'Testing',
  //       icon: 'mdi-gold',
  //       to: 'testing'
  //     },
  //     {
  //       title: 'Pax Company Sync',
  //       icon: 'mdi-database-sync-outline',
  //       to: 'paxcompanysync'
  //     },
  //     {
  //       title: 'Zoho Company Sync',
  //       icon: 'mdi-database-sync-outline',
  //       to: 'zohocompanysync'
  //     },
  //     {
  //       title: 'Zoho Contact Sync',
  //       icon: 'mdi-database-sync-outline',
  //       to: 'zohocontactsync'
  //     },
  //     {
  //       title: 'Cheatsheet',
  //       icon: 'mdi-rocket',
  //       to: 'cheatsheet'
  //     }
  //   ]
  // },
  // {    
  //   title: 'Settings',
  //   icon: 'mdi-cogs',
  //   group: 'pages',
  //   children: [
  //     {
  //       title: 'Xero',
  //       icon: 'mdi-cash-register',
  //       to: 'xerosettings'
  //     }
  // ]
  //}
];
