const { parse } = require('json2csv');
const xlsx = require('json-as-xlsx');
import { format, parseISO, addWeeks, intervalToDuration } from 'date-fns';

export const getIsInDevelopment = () => {
  return process.env.NODE_ENV === 'development';
}

export const getLiveFrontEndURL = 'https://trevor.ccup.uk';

export const getLiveAPIUrl = 'https://trevor-api.ccup.uk';

export const getDevelopmentFrontEndURL = 'http://localhost:8080';

export const getDevelopmentAPIURL = 'https://localhost:44365';

export const getAzureClientId = '49c1afac-f971-4c21-a98c-91d92b919172';

export const getCommissionFromObj = function(commission) {
  return (commission.poSalesValue - commission.costPrice) * (commission.rate / 100);
}

export const getTimeUntilNextCommissionCutOff = function (quarterNumber, yearNumber) {
  var monthBarrier = quarterNumber * 3;

  var dateCutOff = new Date(yearNumber, monthBarrier, 1);

  var returnDate = addWeeks(dateCutOff, 1);

  return returnDate;
}

export const getDurationFromTwoDates = function (startDate, endDate) {
  return intervalToDuration({
    start: startDate,
    end: endDate
  });
}

export const toUTCDate = function (date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
}

export const convertJSONtoXLSX = function (Columns, Data, sheetName, fileName) {
  let data = [
    {
      sheet: sheetName,
      columns: Columns,
      content: Data
    }
  ];

  let settings = {
    fileName: fileName
  };

  xlsx(data, settings);
}

export const getPercentageIncrease = (firstNum, secondNum) => {
  if (firstNum == 0 && secondNum > 0) {
    return 0;
  }

  if (secondNum == 0 && firstNum > 0) {
    return 100;
  }

  var diff = secondNum - firstNum;

  diff = (diff / firstNum) * 100;

  return diff;
}

export const generateURL = (baseUrl, arrayOfQueryStrings) => {
  if (!arrayOfQueryStrings) {
    return baseUrl;
  }

  var hasAddedOne = false;

  arrayOfQueryStrings.forEach(qStringPair => {
    baseUrl += (hasAddedOne ? '&' : '?');

    baseUrl += qStringPair.key + '=' + qStringPair.value;

    hasAddedOne = true;
  });

  return baseUrl;
}

export const getMinMaxDatesByDate = function (date) {
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);

  return {
    startDate: firstDay,
    endDate: lastDay
  };
}

export const isOpen = function (url) {
  const openPathNames = ['/tv'];

  return openPathNames.some(x => url.startsWith(x));
}


export const AvatarImage = function (d, letters, size, bgColor) {
  var canvas = d.createElement('canvas');
  var context = canvas.getContext("2d");
  var size = size || 60;
  var dataURI = '';

  // Generate a random color every time function is called      
  var color = "#" + (Math.random() * 0xFFFFFF << 0).toString(16);

  if (bgColor) {
    color = '#' + bgColor;
  }

  // Set canvas with & height
  canvas.width = size;
  canvas.height = size;
  // Select a font family to support different language characters
  // like Arial
  context.font = Math.round(canvas.width / 2) + "px Arial";
  context.textAlign = "center";
  // Setup background and front color
  context.fillStyle = color;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.fillStyle = "#FFF";
  context.fillText(letters, size / 2, size / 1.5);
  // Set image representation in default format (png)
  dataURI = canvas.toDataURL();
  // Dispose canvas element
  canvas = null;
  return dataURI;
}
export const isValidDate = function (d) {
  return d instanceof Date && !isNaN(d);
}

export const getMaxDateFromArray = function (arrayOfDates) {
  var max = arrayOfDates
    .map((x) => new Date(x))
    .reduce(function (a, b) {
      return a > b ? a : b;
    });

  return max;
}

export const getMinDateFromArray = function (arrayOfDates) {
  var min = arrayOfDates
    .map((x) => new Date(x))
    .reduce(function (a, b) {
      return a < b ? a : b;
    });

  return min;
}

export const getUserNameSeperated = (name) => {
  if (!name) {
    return {
      firstName: 'Error',
      lastName: 'Error'
    };
  }

  if (!name.includes(' ')) {
    return {
      firstName: name,
      lastName: ''
    };
  }

  var splitName = name.toString().split(' ')

  if (splitName.length == 2) {
    return {
      firstName: splitName[0],
      lastName: splitName[1]
    };
  }

  return {
    firstName: 'Michael',
    lastName: 'Jackson'
  }
}

export const concatenateStringByLength = (inputString, length) => {
  return inputString.substring(0, length);
}

export const convertMinutesToHoursAndMinutes = (n) => {
  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return {
    hours: rhours,
    minutes: rminutes
  };
}

export const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const paginate = (array, page_size, page_number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export const convertJSONtoCSV = (headers, data) => {
  var opts = { fields: headers }

  try {
    const csv = parse(data, opts);
    return csv;
  } catch (err) {
    console.error(err);
  }

}

export const deepDiffMapper = function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map: function (obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw 'Invalid argument. Function given, object expected.';
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1
        };
      }

      var diff = {};
      for (var key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        var value2 = undefined;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        diff[key] = this.map(obj1[key], value2);
      }
      for (var key in obj2) {
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue;
        }

        diff[key] = this.map(undefined, obj2[key]);
      }

      return diff;

    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (x) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray: function (x) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate: function (x) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject: function (x) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue: function (x) {
      return !this.isObject(x) && !this.isArray(x);
    }
  }
};