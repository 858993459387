import * as generalFuncs from '../utilities/GeneralUtilities'
import * as Msal from '@azure/msal-browser';

export default (to, from, next) => {
  next();
}


export const getMsalConfig = function () {
  var authObject = {
    auth: {
      clientId: generalFuncs.getAzureClientId,
      authority: "https://login.microsoftonline.com/be541257-aca9-4ffd-af3d-aa2373006513/"
    }
    ,
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
    },
  }

  if (generalFuncs.getIsInDevelopment()) {
    authObject.auth.redirectUri = generalFuncs.getDevelopmentFrontEndURL + "/app/pages/Blank";
    authObject.auth.postLogoutRedirectUri = generalFuncs.getDevelopmentFrontEndURL + "/logout";
  } else {
    authObject.auth.redirectUri = generalFuncs.getLiveFrontEndURL + "/app/pages/Blank";
    authObject.auth.postLogoutRedirectUri = generalFuncs.getLiveFrontEndURL + "/logout";
  }

  return authObject;
};

export const getMsalPublicClient = function () {
  return new Msal.PublicClientApplication(getMsalConfig());
}

export const getLoginRequest = function () {
  return {
    scopes: ["api://4c3d7f93-369a-4d8a-9163-3910b3c8dec4/UseTrev", "User.ReadBasic.All"]
  }
}

export const getTokenRequest = function (account) {
  return {
    scopes: ["api://4c3d7f93-369a-4d8a-9163-3910b3c8dec4/UseTrev"],
    account: account
  };
}

export const LoginAndGetToken = async function (response) {
  const msalInstance = getMsalPublicClient();

  if (response !== null) {
    //console.log('setting response account to active');
    //console.log(response.account);
    msalInstance.setActiveAccount(response.account);
    //console.log(msalInstance.getActiveAccount());
    var tokenResponse = await getToken();
    tokenResponse.loginType = 'Initial Post Login';
    return tokenResponse;
  } else {
    const currentAccounts = msalInstance.getAllAccounts();

    if (currentAccounts.length === 0) {
      // no accounts signed-in, attempt to sign a user in
      var loginRequest = getLoginRequest();
      msalInstance.loginRedirect(loginRequest);
    } else if (currentAccounts.length > 1) {
      //use the active account?          
      var activeAccount = msalInstance.getActiveAccount();
      let silentTokenRequest = getTokenRequest(activeAccount);

      silentTokenRequest.state = document.referrer;

      try {
        var tokenResponse = await msalInstance.acquireTokenSilent(silentTokenRequest);

        return {
          idToken: tokenResponse.idToken,
          tenantId: tokenResponse.tenantId,
          loginType: 'Silently Acquired Token Multi',
          accessToken: tokenResponse.accessToken,
          state: tokenResponse.state
        };
      } catch (error) {
        console.log('error logging in');
        if (error) {
          msalInstance.loginRedirect(getLoginRequest());
        }
      }

    } else if (currentAccounts.length === 1) {
      let silentTokenRequest = getTokenRequest(currentAccounts[0]);

      var tokenResponse = await msalInstance.acquireTokenSilent(silentTokenRequest);
      return {
        idToken: tokenResponse.idToken,
        tenantId: tokenResponse.tenantId,
        accessToken: tokenResponse.accessToken,
        loginType: 'Silently Acquired Token'
      };
    }
  }
}

export const getToken = async function () {
  const msalInstance = getMsalPublicClient();

  const currentAccounts = msalInstance.getAllAccounts();

  if (currentAccounts.length === 0) {
    // no accounts signed-in, attempt to sign a user in
    msalInstance.loginRedirect(getLoginRequest());
  } else if (currentAccounts.length > 1) {
    // Add choose account code here
    //get the active account and use that one?        

    var activeAccount = msalInstance.getActiveAccount();

    //console.log(activeAccount);

    let silentTokenRequest = getTokenRequest(activeAccount);
    try {
      var tokenResponse = await msalInstance.acquireTokenSilent(silentTokenRequest);
      //console.log(tokenResponse);            

      return {
        idToken: tokenResponse.idToken,
        tenantId: tokenResponse.tenantId,
        accessToken: tokenResponse.accessToken,
        loginType: 'Silently Acquired Token Multi'
      };
    } catch (error) {
      if (error) {
        msalInstance.loginRedirect(getLoginRequest());
      }
    }

  } else if (currentAccounts.length === 1) {
    //need to acquire token i think

    //this will happen every time the page is loaded and a user is logged in
    let silentTokenRequest = getTokenRequest(currentAccounts[0]);

    try {
      var tokenResponse = await msalInstance.acquireTokenSilent(silentTokenRequest);

      return {
        idToken: tokenResponse.idToken,
        accessToken: tokenResponse.accessToken,
        tenantId: tokenResponse.tenantId,
        loginType: 'Silently Acquired Token'
      };
    } catch (error) {
      //there is a problem with the acquire token silent - redirect to login
      console.log('issue with login in token get ');
      console.log(error)
      if (error) {
        msalInstance.loginRedirect(getLoginRequest());
      }
    }
  }
}