import * as general from './GeneralUtilities'
import store from '../store/index';
import * as auth from '../auth/authenticate'
import * as ProductUtilities from '../utilities/Object Utilities/Products'

export class baseCourier {
    constructor(url) {
        if (general.getIsInDevelopment()) {
            this.urlEndPoint = general.getDevelopmentAPIURL + '/api/' + url;
            this.baseURL = general.getDevelopmentAPIURL + '/api/';
        } else {
            this.urlEndPoint = general.getLiveAPIUrl + '/api/' + url;
            this.baseURL = general.getLiveAPIUrl + '/api/';
        }
    }

    urlEndPoint = '';
    baseURL = '';

    removeById(id) {
        this.checkAuth();

        let url = this.urlEndPoint + '/' + id;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'DELETE',
                headers: getHeaders()
            }).then(response => {
                if (response.status != 404 && response.status != 401 && response.status != 500) {
                    resolve();
                } else {
                    reject(error);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }

    updateById(id, objectToUpdate, returnAsJson = true) {
        this.checkAuth();

        let url = this.urlEndPoint + '/' + id;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'PUT',
                headers: getHeaders(),
                body: JSON.stringify(objectToUpdate)
            }).then(response => {
                if (response.status != 404 && response.status != 401 && response.status != 500) {
                    if (returnAsJson) {
                        response.json().then(dataAsJson => {
                            resolve(dataAsJson);
                        });
                    } else {
                        resolve(response);
                    }
                } else {
                    reject(error);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }

    add(newObject, useAddedBy = true, additionalQueryStrings) {
        this.checkAuth();

        var url = '';

        if (additionalQueryStrings) {
            url = general.generateURL(this.urlEndPoint, additionalQueryStrings);
        } else {
            url = this.urlEndPoint;
        }

        if (useAddedBy) {
            newObject.addedBy = store.getters.userFullName
        }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(newObject)
            }).then(response => {
                if (response.status != 404 && response.status != 401 && response.status != 500) {
                    response.json().then(dataAsJson => {
                        resolve(dataAsJson);
                    }).catch(error => {
                        reject(error);
                    });
                } else {
                    reject('Not found or unauthorised or error');
                }
            }).catch(error => {
                reject(error);
            })
        })
    }

    getById(id, additionalQueryStrings) {
        this.checkAuth();

        let url = '';

        if (additionalQueryStrings) {
            url = general.generateURL(this.urlEndPoint + '/' + id, additionalQueryStrings);
        } else {
            url = this.urlEndPoint + '/' + id;
        }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: getHeaders()
            }).then(response => {
                if (response.status != 404 && response.status != 401 && response.status != 500) {
                    response.json().then(dataAsJson => {
                        resolve(dataAsJson);
                    }).catch(error => {
                        reject(error);
                    });
                } else {
                    reject(response.status);
                }
            }).catch(error => {
                reject(error);
            })
        })
    }

    withId(generalId) {
        if (!generalId) {
            console.warn('No General Id for withId Courier of Type: ' + this.constructor.name);
        }

        var childClass = eval(this.constructor.name);
        return new childClass(this.urlEndPoint.replace(this.baseURL, '') + '/' + generalId);
    }

    checkAuth() {
        auth.getToken().then(x => {
            store.commit('setUser', x);
        });
    }

    getData(pageNumber, pageSize, searchString, dateStart, dateEnd, additionalQueryStrings) {
        this.checkAuth();

        return new Promise((resolve, reject) => {
            var allQStrings = [
                { key: 'PageNumber', value: (pageNumber ?? 0) },
                { key: 'PageSize', value: (pageSize ?? 10) },
            ];

            if (dateStart != '' && dateStart != null) {
                allQStrings.push({ key: 'dateStart', value: dateStart.toISOString() });
            }

            if (dateEnd != '' && dateEnd != null) {
                allQStrings.push({ key: 'dateEnd', value: dateEnd.toISOString() });
            }

            if (additionalQueryStrings && additionalQueryStrings.length > 0) {
                additionalQueryStrings.forEach(x => {
                    allQStrings.push(x);
                });
            }

            if (searchString) {
                allQStrings.push({ key: 'search', value: searchString });
            }

            var urlToPing = general.generateURL(this.urlEndPoint, allQStrings);

            fetch(urlToPing, {
                method: 'GET',
                headers: getHeaders(),
            }).then(responseData => {
                responseData.json().then(responseDataAsJson => {
                    resolve(responseDataAsJson);
                }).catch(errorAlso => {
                    reject(errorAlso);
                });
            }).catch(error => {
                reject(error);
            })
        })
    }

    genericPut(url) {
        this.checkAuth();

        var fetchObjct = {
            method: 'PUT',
            headers: getHeaders()
        };

        return new Promise((resolve, reject) => {
            fetch(this.urlEndPoint + '/' + url, fetchObjct).then(response => {
                if (response.ok) {
                    var contentType = response.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        response.json().then(respAsJson => {
                            resolve(respAsJson);
                        }).catch(error => {
                            reject(error);
                        })
                    }
                    else {
                        resolve();
                    }
                }
                else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        })
    }

    genericGet(url, responseAsJson = true) {
        this.checkAuth();

        var fetchObject = {
            method: 'GET',
            headers: getHeaders(),
        };

        return new Promise((resolve, reject) => {
            fetch(this.urlEndPoint + '/' + url, fetchObject).then(response => {
                if (response.status != 404 && response.status != 401 && response.status != 500) {
                    if (responseAsJson) {
                        response.json().then(dataAsJson => {
                            resolve(dataAsJson);
                        }).catch(error => {
                            reject(error);
                        });
                    }
                    else {
                        resolve();
                    }
                } else {
                    reject(error);
                }
            }).catch(error => {
                reject(error);
            });
        })
    }

    genericPost(url, inputObj, responseAsJson = true) {
        this.checkAuth();

        var fetchObject = {
            method: 'POST',
            headers: getHeaders(),
        };

        if (inputObj) {
            fetchObject.body = JSON.stringify(inputObj);
        }

        return new Promise((resolve, reject) => {
            fetch(this.urlEndPoint + '/' + url, fetchObject).then(response => {
                if (responseAsJson) {
                    response.json().then(data => {
                        resolve(data);
                    }).catch(error => {
                        reject(error);
                    })
                } else {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }

    executeAction(actionname, inputObject, useHeaders = true) {


        var fetchObject = {
            method: 'POST',
        };

        if (useHeaders) {
            fetchObject.headers = getHeaders();
            this.checkAuth();
        }

        if (inputObject) {
            fetchObject.body = JSON.stringify(inputObject);
        }

        return new Promise((resolve, reject) => {
            fetch(this.urlEndPoint + '/' + actionname, fetchObject).then(responseData => {
                resolve(responseData);
            }).catch(error => {
                reject(error);
            });
        });
    }
}

const getHeaders = function () {
    var h = new Headers();
    h.append('Authorization', "Bearer " + store.getters.getTrevorAccessToken);
    h.append('content-type', "application/json");
    return h;
}

export class Courier {
    constructor() {
        this.ColinCompanies = new ColinCompanyCourier();
        this.ColinAddresses = new ColinAddressCourier();
        this.Companies = new CompanyCourier();
        this.Contacts = new ContactCourier();
        this.Sites = new SiteCourier();
        this.ProductSkeletons = new ProductSkeletonCourier();
        this.Products = new ProductCourier();
        this.MetaData = new MetaDataCourier();
        this.PriceRules = new ProductPriceCourier();
        this.Contracts = new ContractCourier();
        this.SystemUsers = new SystemUserCourier();
        this.Permissions = new PermissionCourier();
        this.PermissionGroups = new PermissionGroupCourier();
        this.Roles = new RoleCourier();
        this.Pax8 = new PaxCourier();
        this.PaxExternal = new PaxExternalCourier();
        this.Zoho = new ZohoActionCourier();
        this.Domains = new CompanyDomainCourier();
        this.ReportSQLActions = new ReportSQLActionCourier();
        this.ReportSQL = new ReportSQLCourier();
        this.Reports = new ReportsCourier();
        this.ZohoTickets = new ZohoTicketCourier();
        this.ZohoTimeEntries = new ZohoTicketTimeEntryCourier();
        this.Open = new OpenCourier();
        this.PaxLinks = new PaxLinkCourier();
        this.Hudu = new HuduCourier();
        this.ZohoDeskExternal = new ZohoDeskExternalCourier();
        this.ZohoCRMExternal = new ZohoCRMExternalCourier();
        this.RMM.Organisations = new NinjaOrganisationCourier();
        this.Invoices = new InvoiceCourier();
        this.InvoiceLineItems = new InvoiceLineItemCourier();

        this.Jobs = new JobCourier();
        this.JobLineItems = new JobLineItemCourier();
        this.JobNotes = new JobNoteCourier();

        this.NinjaLinks = new NinjaLinkCourier();

        this.Emails = new CompanyEmailCourier();

        this.Triggers = new TriggerCourier();
        this.NinjaExternal = new NinjaExternalCourier();
        this.ContactTags = new ContactTagCourier();
        this.Approvals = new ApprovalCourier();
        this.ZohoTicketsRaw = new ZohoTicketRawCourier();

        this.XeroLinks = new XeroLinkCourier();
        this.XeroContactExternal = new XeroContactExternalCourier();
        this.XeroOrganisationProfiles = new XeroOrganisationCourier();
        this.XeroInvoiceExternal = new XeroInvoiceExternalCourier();
        this.XeroAccountExternal = new XeroAccountExternalCourier();
        this.Scopes = new ScopeCourier();
        this.ScopeGroups = new ScopeGroupCourier();

        this.ZohoDeals = new ZohoDealCourier();

        this.Commissions = new CommissionCourier();
        this.CommissionNotes = new CommissionNoteCourier();
        this.CommissionActions = new CommissionActionCourier();
    }

    //Core Stuff
    ColinCompanies = {};
    ColinAddresses = {};
    Companies = {};
    Contacts = {};
    Sites = {};
    Contracts = {};
    ChargeItems = {};
    Domains = {};
    Emails = {};
    Triggers = {};
    ContactTags = {};
    Approvals = {};
    Jobs = {};
    JobLineItems = {};
    JobNotes = {};

    Commissions = {};
    CommissionNotes = {};
    CommissionActions = {};

    //User and Permission Stuff
    SystemUsers = {};
    Permissions = {};
    PermissionGroups = {};
    Roles = {};

    //Product Stuff
    ProductSkeletons = {};
    Products = {};
    MetaData = {};
    PriceRules = {};

    //Invoice Stuff
    Invoices = {};
    InvoiceLineItems = {};

    //Pax8 Stuff
    Pax8 = {};
    PaxLinks = {};
    PaxExternal = {};

    //Hudu Stuff
    Hudu = {};

    //Zoho Stuff
    Zoho = {};
    ZohoTickets = {};
    ZohoDeskExternal = {};
    ZohoCRMExternal = {};
    ZohoTicketsRaw = {};
    ZohoDeals = {};
    ZohoTimeEntries = {};

    //Reports
    ReportSQLActions = {};
    ReportSQL = {};
    Reports = {};

    //Open
    Open = {};

    //RMM
    RMM = {
        Organisations: {}
    };

    //Ninja Stuff
    NinjaLinks = {};
    NinjaExternal = {};

    //Xero Stuff
    XeroLinks = {};
    XeroContactExternal = {};
    XeroInvoiceExternal = {};
    XeroAccountExternal = {};
    XeroOrganisationProfiles = {};

    Scopes = {};
    ScopeGroups = {};


}

export class ReportsCourier extends baseCourier {
    constructor() {
        super('StaticReport');
    }

    CompanyMicrosoftProducts = async () => {
        return await this.genericGet('MicrosoftProduct', true);
    }
}

export class ZohoDealCourier extends baseCourier {
    constructor() {
        super('ZohoDeal');
    }
}

export class CommissionCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Commission');
            this.Notes = new CommissionNoteCourier(url);
            this.TimeLine = new CommissionActionCourier(url);
        }
        else {
            super('Commission')
        }
    }

    getCommissionDetailsByQuarter = async (quarterNumber, year, statusName) => {
        return await this.genericGet('GetCommissionDetails?quarter=' + quarterNumber + '&year=' + year + '&status=' + statusName);
    }

    finaliseQuarter = (year, quarterNumber) => {
        return this.genericGet('FinaliseQuarter?Year=' + year + "&Quarter=" + quarterNumber, false);
    }

    findInvoiceById = (tenantId, invoiceId) => {
        return this.genericGet('GetInvoiceById?tenantId=' + tenantId + '&invoiceId=' + invoiceId);
    }

    findInvoices = (tenantId, pageNumber, search) => {
        return this.genericGet('FindInvoice?tenantId=' + tenantId + '&pageNumber=' + pageNumber + '&search=' + search);
    }

    approveCommission = async (commissionId) => {
        return await this.genericPut(commissionId + '/Approve');
    }

    denyCommission = async (commissionId) => {
        return await this.genericPut(commissionId + '/Deny');
    }

    voidCommission = async (commissionId) => {
        return await this.genericPut(commissionId + '/Void');
    }


    Notes = {};
    TimeLine = {};
}

export class CommissionActionCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/CommissionAction');
        } else {
            super('CommissionAction')
        }
    }
}

export class CommissionNoteCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/CommissionNote');
        }
        else {
            super('CommissionNote');
        }
    }
}

export class ScopeCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Scope');
        }
        else {
            super('Scope')
        }
    }
}

export class ScopeGroupCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/ScopeGroup');
            this.Scopes = new ScopeCourier(url);
        } else {
            super('ScopeGroup');
        }
    }

    Scopes = {};
}

export class InvoiceCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Invoice');
            this.LineItems = new InvoiceLineItemCourier(url);
        } else {
            super('Invoice');
        }
    }

    LineItems = {};
}

export class InvoiceLineItemCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/LineItem');
        } else {
            super('LineItem');
        }
    }
}

export class ColinAddressCourier extends baseCourier {
    constructor() {
        super('ColinAddress');
    }
}

export class XeroInvoiceExternalCourier extends baseCourier {
    constructor() {
        super('XeroInvoiceExternal');
    }


}

export class XeroAccountExternalCourier extends baseCourier {
    constructor() {
        super('XeroAccountExternal');
    }
}

export class XeroOrganisationCourier extends baseCourier {
    constructor() {
        super('XeroOrgProfile')
    }
}

export class XeroLinkCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/XeroLink');
        } else {
            super('XeroLink');
        }
    }
}

export class ContactTagCourier extends baseCourier {
    ////Expects id not the rest
    constructor(url) {
        if (url) {
            super(url + '/Tag');
        } else {
            super('ContactTag');
        }
    }
}

export class ZohoCRMExternalCourier extends baseCourier {
    constructor() {
        super('ZohoCRM');
    }
}

export class ZohoDeskExternalCourier extends baseCourier {
    constructor() {
        super('ZohoDesk');
    }
}

export class HuduCourier extends baseCourier {
    constructor() {
        super('HuduExternal');
    }
}

export class TriggerCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Trigger');
        } else {
            super('Trigger');
        }
    }

    RunTrigger = async (id, companyName, companyId, customKeys) => {
        return await this.genericPost('RunTrigger', {
            id: id,
            companyName: companyName,
            companyId: companyId,
            customValues: customKeys
        }, true);
    }
}

export class ReportSQLCourier extends baseCourier {
    constructor() {
        super('Report');
    }
}

export class OpenCourier extends baseCourier {
    constructor() {
        super('Open');
    }

    GetSupportAgentDashData = async (secret) => {
        return await this.executeAction('SupportAgentsTV?secret=' + secret, null, false);
    }
}

export class ZohoTicketThreadCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Thread');
        }
    }
}

export class ZohoTicketTimeEntryCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/TimeEntry');
        }
        else {
            super('TimeEntry')
        }
    }

    AddInvoiceDetails = async (timeEntryId, invoiceId, invoiceNumber, invoiceReference) => {
        return await this.genericPut(timeEntryId + '/AddInvoiceId?invoiceId=' + invoiceId + '&invoiceNumber=' + invoiceNumber + '&invoiceReference=' + invoiceReference)
    }
}

export class ApprovalCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Approval');
            this.Contacts = new ContactCourier(url + '/Contact', false);
        } else {
            super('Approval');
        }
    }

    SendEmails = async (approvalId) => {
        return await this.executeAction('SendApprovalEmails?ApprovalId=' + approvalId);
    }
    Contacts = {};
}

export class ZohoTicketCommentCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Comment');
        } else {
            throw 'Tried to access comments courier without parent ticket id';
        }
    }
}

export class ZohoTicketCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + "/ZohoTicket");

            this.Threads = new ZohoTicketThreadCourier(url);
            this.TimeEntries = new ZohoTicketTimeEntryCourier(url);
        } else {
            super("ZohoTicket");
        }
    }

    Threads = {};
    TimeEntries = {};

    ReProcessTicket = async (ticketId) => {
        return await this.genericGet('ReProcessTicket?TicketId=' + ticketId);
    }

    getByZohoId = async (zohoId) => {
        return await this.genericGet('zohoId/' + zohoId);
    }

    GetStatuses = this.GetStatuses = async () => {
        return await this.genericGet('AllStatuses');
    }

    GetCategories = this.GetCategories = async () => {
        return await this.genericGet('AllCategories');
    }

    GetTodaysTicketsByUser = async () => {
        return await this.genericGet('ZohoTicketPerDay');
    }

    GetTodaysTickets = async () => {
        return await this.genericGet('TicketsToday');
    }
}

export class ZohoTicketRawCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super('ZohoTicketRaw');
            this.Comments = new ZohoTicketRawCommentCourier(url + '/Comment');
        }
        else {
            super('ZohoTicketRaw');
        }
    }

    Comments = {};
}

export class ZohoTicketRawCommentCourier extends baseCourier {
    constructor(url) {
        if (!url) {
            throw new Error('Tried to use Zoho Ticket Raw Comment Courier Without Ticket Id');
        }
        else {
            super(url);
        }
    }
}

export class ReportSQLActionCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + "/ReportAction");
        }
        else {
            super('ReportAction');
        }
    }

    RunReport = async (reportObject) => {
        return await this.executeAction('RunReport', reportObject);
    }
}

export class CompanyDomainCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Domain');
        }
        else {
            super('Domain')
        }
    }
}

export class CompanyEmailCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Email');
        }
        else {
            super('Email')
        }
    }
}

export class PaxCourier extends baseCourier {
    constructor() {
        super('PaxAction');
    }

    SyncAndGetDiffCompanies = async () => {
        return await this.executeAction('SyncCompanies');
    }
}

export class ZohoCompanyCRMCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/CompaniesCRM');
        }
    }
}

export class ZohoCompanyDeskCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/CompaniesDesk');
        }
    }
}

export class CompanyZohoTickets extends baseCourier {
    constructor(url) {
        super(url + '/ZohoTicket');
    }

    GetTicketStatuses = async () => {
        return await this.genericGet('TicketStatuses');
    }
}

export class ZohoActionCourier extends baseCourier {
    constructor() {
        super('ZohoAction');

        this.Companies = new ZohoCompanyCRMCourier('ZohoAction');
        this.CompaniesDesk = new ZohoCompanyDeskCourier('ZohoAction');
    }

    SyncAndGetDiffCompanies = async () => {
        return await this.executeAction('SyncCompanies');
    }

    SyncAndGetDiffContacts = async () => {
        return await this.executeAction('SyncContacts');
    }

    TicketData = async (ticketDataConfig) => {
        return await this.executeAction('TicketData', ticketDataConfig, true);
    }

    TicketDataByTime = async (ticketDataConfig) => {
        return await this.executeAction('TicketData', ticketDataConfig, true);
    }

    GetTopCompaniesByTimeSpent = async (config) => {
        return await this.executeAction('TopCompaniesByTickets', config, true);
    }

    GetTimeBankUsageReport = async (dateStart, dateEnd) => {
        return new Promise((resolve, reject) => {
            this.genericGet('ReportCompaniesTimeBankUsage?StartDate=' + dateStart + "&EndDate=" + dateEnd).then(data => {
                resolve(data);
            }).catch(error => reject(error));
        });
    }

    GetAllYouCanEatReport = async (dateStart, dateEnd) => {
        return new Promise((resolve, reject) => {
            this.genericGet('ReportCompaniesAllYouCanEat?StartDate=' + dateStart + "&EndDate=" + dateEnd).then(data => {
                resolve(data);
            }).catch(error => reject(error));
        });
    }

    GetPayAsYouGo5HoursUsageReport = async (dateStart, dateEnd) => {
        return new Promise((resolve, reject) => {
            this.genericGet('ReportCompaniesPayAsYouGo5HoursUsage?StartDate=' + dateStart.toISOString() + "&EndDate=" + dateEnd.toISOString()).then(data => {
                resolve(data);
            }).catch(error => reject(error));
        })
    }

    GetPayAsYouGoUsageReport = async (dateStart, dateEnd) => {
        return new Promise((resolve, reject) => {
            this.genericGet('ReportCompaniesPayAsYouGoUsage?StartDate=' + dateStart + '&EndDate=' + dateEnd).then(data => {
                resolve(data);
            }).catch(error => reject(error));
        })
    }

    GetTicketsByProductSkuAndTimeType = async (CompanyId, ProductSku, UseType, StartDate, EndDate, PageNumber, PageSize, Search) => {
        return new Promise((resolve, reject) => {
            var queryStrings = "?CompanyId=" + CompanyId + "&ProductSku=" + ProductSku + "&UseType=" + UseType +
                "&StartDate=" + StartDate.toISOString() + "&EndDate=" + EndDate.toISOString() + "&PageNumber=" + PageNumber + "&PageSize=" + PageSize + "&Search=" + Search;
            this.genericGet('TicketsByProductAndTime' + queryStrings).then(data => {
                resolve(data);
            }).catch(error => reject(error));
        })
    }

    Companies = {};

    CompaniesDesk = {};
}

export class ChargeItemCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
        }
        else {
            super('ChargeItem');
        }
    }
}

export class PermissionCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
        }
        else {
            super('Permission')
        }
    }

    buildPermissionGraph() {
        return this.executeAction('BuildPermissionGraph');
    }
}

export class PermissionGroupCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
            this.Permissions = new PermissionCourier(url + '/Permission');
        }
        else {
            super('PermissionGroup')
        }
    }

    Permissions = {};
}

export class RoleCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
            this.PermissionGroups = new PermissionGroupCourier(url + '/PermissionGroup');
            this.Permissions = new PermissionCourier(url + '/Permission');
        }
        else {
            super('Role')
        }
    }

    PermissionGroups = {};
    Permissions = {};

}

export class SystemUserCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
            this.Roles = new RoleCourier(url + '/Role');
            this.ZohoTickets = new ZohoTicketCourier(url);
            this.ScopeGroups = new ScopeGroupCourier(url);
            this.Commissions = new CommissionCourier(url);
        }
        else {
            super('SystemUser');
        }
    }

    Roles = {};
    ZohoTickets = {};
    ScopeGroups = {};
    Commissions = {};

    GetCurrentUserScopes = async () => {
        return this.genericGet('GetScopes');
    }

    GetCurrentUserId = async () => {
        return this.genericGet('CurrentUser');
    }

    //Functions
    UserExists = async () => {
        return this.executeAction('CheckUser').then(x => {
            if (x.status == 404 || x.status == 401) {
                return false;
            } else {
                return true;
            }
        }).catch(() => {
            return false;
        })
    }
}

export class ContractCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
            this.Products = new ProductCourier(url + '/Product')
        } else {
            super('Contract')
        }
    }

    Products = {};
}

export class ProductSkeletonCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
            this.PriceRules = new ProductPriceCourier(url + '/PriceRule');
            this.MetaData = new MetaDataCourier(url + '/MetaData');
            this.LinkedProductSkeletons = new LinkedProductSkeletonCourier(url);
        } else {
            super('ProductSkeleton');
        }
    }

    PriceRules = {};
    MetaData = {};
    LinkedProductSkeletons = {};

    Functions = ProductUtilities;
}

export class LinkedProductSkeletonCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/LinkedProductSkeleton')
        }
        else {
            console.error('Product Skeleton Link Courier Doesnt Support No Url');
        }
    }
}

export class MetaDataCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url)
        } else {
            super('MetaData');
        }
    }
}

export class ProductCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
            this.PriceRules = new ProductPriceCourier(url + '/PriceRule');
            this.MetaData = new MetaDataCourier(url + '/MetaData');
            this.LinkedProducts = new ProductLinkCourier(url);
        } else {
            super('Product');
        }
    }

    PriceRules = {};
    MetaData = {};
    LinkedProducts = {};

    UpdatePriceInPax = async (productId, newPrice) => {
        return await this.genericPut(productId + '/UpdatePriceInPax?newPrice=' + newPrice);
    }

    AddInvoiceToProduct = async (productId, invoiceNumber) => {
        return await this.genericPut(productId + '/Invoice/' + invoiceNumber);
    }

    Functions = ProductUtilities;
}

export class ProductLinkCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/LinkedProduct');
        }
        else {
            super('LinkedProduct')
        }
    }
}

export class ProductPriceCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
        } else {
            super('ProductPriceRule');
        }
    }
}

export class ColinCompanyCourier extends baseCourier {
    constructor() {
        super('ColinCompany');
    }
}

export class CompanyCourier extends baseCourier {
    constructor(url) {
        super('Company');

        if (url) {
            this.Contacts = new ContactCourier(url + '/Contact');
            this.Contracts = new ContractCourier(url + '/Contract');
            this.Sites = new SiteCourier(url + '/Site');
            this.ProductSkeletons = new ProductSkeletonCourier(url + '/ProductSkeleton')
            this.Products = new ProductCourier(url + '/Product')
            this.ChargeItems = new ChargeItemCourier(url + '/ChargeItem')
            this.PaxLinks = new PaxLinkCourier(url + '/PaxLink')
            this.ZohoTickets = new CompanyZohoTickets(url);
            this.Domains = new CompanyDomainCourier(url);
            this.Emails = new CompanyEmailCourier(url);
            this.CompanyId = url.substring(url.indexOf('/') + 1);
            this.NinjaLinks = new NinjaLinkCourier(url + '/NinjaLink');
            this.Triggers = new TriggerCourier(url);
            this.Approvals = new ApprovalCourier(url);
            this.XeroLinks = new XeroLinkCourier(url);
            this.Invoices = new InvoiceCourier(url);

            this.Jobs = new JobCourier(url + '/Job');
        }
    }

    Contacts = {};
    Contracts = {};
    Sites = {};
    ProductSkeletons = {};
    Products = {};
    ChargeItems = {};
    PaxLinks = {};
    ZohoTickets = {};
    Domains = {};
    Emails = {};
    Triggers = {};
    Approvals = {};

    NinjaLinks = {};

    Invoices = {};

    XeroLinks = {};
    Jobs = {};

    CompanyId = '';

    GetTicketTimeUsed = async (dateStart, dateEnd, categoriesAsArray) => {
        var urlString = this.CompanyId + "/TimeUsedByDates?dateStart=" + dateStart + "&dateEnd=" + dateEnd;

        if (categoriesAsArray != null && categoriesAsArray.length > 0) {
            var categoriesAsString = categoriesAsArray.join();
            urlString += "&Categories=" + categoriesAsString;
        }

        return await this.genericGet(urlString);
    }

    GetTimeUsedByProducts = async (dateStart, dateEnd) => {
        // var urlString = this.CompanyId + "/TimeUsedByProducts?dateStart=" + dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate() +
        //     "&dateEnd=" + dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate();


        var urlString = this.CompanyId + "/TimeUsedByProducts?dateStart=" + general.toUTCDate(dateStart) + "&dateEnd=" + general.toUTCDate(dateEnd);

        return await this.genericGet(urlString);
    }
}

export class XeroContactExternalCourier extends baseCourier {
    constructor() {
        super('XeroContactExternal');
    }
}

export class NinjaLinkCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
        } else {
            super('NinjaLink');
        }
    }
}

export class PaxLinkCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
        } else {
            super('PaxLink');
        }
    }
}

export class ContactCourier extends baseCourier {
    constructor(url, genApproval = true) {
        if (url) {
            super(url);
            this.Tags = new ContactTagCourier(url);
            this.ZohoTickets = new ZohoTicketCourier(url);
            if (genApproval) {
                this.Approvals = new ApprovalCourier(url);
            }
        } else {
            super('Contact');
        }
    }

    Tags = {};
    ZohoTickets = {};
    Approvals = {};

    GetTicketsByRange = async (contactId, pageNumber, pageSize, search, startDate, endDate) => {
        var response = await this.genericGet('GetContactTicketsByTimeRange?ContactId=' + contactId + '&PageNumber=' + pageNumber + "&PageSize=" + pageSize + "&Search=" + search + "&StartDate=" + startDate.toLocaleString('en-US') + "&EndDate=" + endDate.toLocaleString('en-US'));

        return response;
    }

    GetTicketTimeUsedByRange = async (contactId, startDate, endDate) => {
        var resp = await this.genericGet('GetContactMinutesByTimeRange?ContactId=' + contactId + '&StartDate=' + startDate.toLocaleString('en-US') + "&EndDate=" + endDate.toLocaleString('en-US'));
        return resp;
    }
}

export class SiteCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
        } else {
            super('Site');
        }
    }
}

export class PaxExternalCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
        }
        else {
            super('PaxExternal');
        }
    }
}
export class NinjaExternalCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url)
        } else {
            super('NinjaExternal');
        }
    }
}

export class NinjaOrganisationCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
            this.Devices = new NinjaOrganisationDeviceCourier(url + '/Devices')
        } else {
            super('Organisation');
        }
    }

    Devices = {};
}

export class NinjaOrganisationDeviceCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
        } else {
            console.error('Requires Parent URL');
        }
    }
}

export class JobCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url);
            this.Notes = new JobNoteCourier(url);
            this.LineItems = new JobLineItemCourier(url)

        } else {
            super('Job');
        }
    }

    LineItems = {};
    Notes = {};
}

export class JobNoteCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/Note');
        }
        else {
            super('JobNote');
        }
    }
}

export class JobLineItemCourier extends baseCourier {
    constructor(url) {
        if (url) {
            super(url + '/LineItem');
        } else {
            super('JobLineItem')
        }
    }
}