/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins";

import octaviaKit from "@/plugins/octavia.kit";

import * as generalFuncs from './utilities/GeneralUtilities';
import { Courier } from './utilities/Courier';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VueNumber from 'vue-number-animation'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

Vue.use(octaviaKit);
Vue.use(VueNumber);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyBWB0q-WFRir51hkgT4peV4NefoeKImMNw'
});
Vue.config.productionTip = false;

var isOpen = generalFuncs.isOpen(window.top.location.pathname);
if (isOpen) {
  Vue.prototype.$courier = new Courier();
  Vue.prototype.$eventBus = new Vue();
  new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
}
else {
  var authenticationService = require('./auth/authenticate');
  var graphService = require('./utilities/GraphApi');

  authenticationService.getMsalPublicClient().handleRedirectPromise().then((response) => {
    authenticationService.LoginAndGetToken(response).then((tokenResponse) => {
      store.commit('setUser', tokenResponse);
      Vue.prototype.$courier = new Courier();
      Vue.prototype.$eventBus = new Vue();

      Vue.use(TiptapVuetifyPlugin, {
        // the next line is important! You need to provide the Vuetify Object to this place.
        vuetify, // same as "vuetify: vuetify"
        // optional, default to 'md' (default vuetify icons before v2.0.0)
        iconsGroup: 'mdi'
      });

      Vue.prototype.$courier.XeroOrganisationProfiles.getData(1, 999, '').then(x => {
        store.commit('setXeroProfiles', x.payload);
      });

      //get the scopes

      Vue.prototype.$courier.SystemUsers.GetCurrentUserId().then(currentUserId => {
        store.commit('setUserId', currentUserId);
      });

      Vue.prototype.$courier.SystemUsers.GetCurrentUserScopes().then(scopes => {
        store.commit('setUserScopes', scopes);

        new Vue({
          store,
          router,
          vuetify,
          render: h => h(App)
        }).$mount("#app");
      })


      authenticationService.getMsalPublicClient().acquireTokenSilent({
        scopes: ['https://graph.microsoft.com/.default']
      }).then(gResponse => {
        store.commit('setGraphAccessToken', gResponse.accessToken);
        //Set Graph API Profile Stuff
        graphService.makeGraphRequest('me/').then(data => {
          if (data) {
            data.json().then(dataAsJson => {
              store.commit('setName', dataAsJson.displayName);
              store.commit('setJobRole', dataAsJson.jobTitle);

              Vue.prototype.$courier.SystemUsers.UserExists().then(x => {
                if (!x) {
                  //redirect to gtfo page

                  router.push({
                    name: 'Error',
                    params: {
                      code: '404',
                      message: 'No User Found'
                    }
                  });
                }
              })

              //try and get profile picture
              graphService.makeGraphRequest('me/photos/48x48/$value').then(response => {
                //there is a photo - set it here
                response.blob().then(graphBlob => {
                  window.URL = window.URL || window.webkitURL;
                  var objURL = window.URL.createObjectURL(graphBlob);
                  store.commit('setUserPhoto', objURL);
                });
              }).catch(error => {
                if (error) {
                  console.log(error);
                  //generate one using the initials thing
                  var imgFromInits = generalFuncs.AvatarImage(document, store.getters.loggedInUserInitials, 50);
                  store.commit('setUserPhoto', imgFromInits);
                }
              });
            })
          }
        })
      }).catch(error => {
        router.push({
          name: 'Error',
          params: {
            code: '500',
            message: error
          }
        });
      });
    });
  })

}
