export const calculatePriceFromRules = function (quantity, basePrice, priceRules) {
    if(!priceRules || priceRules.length === 0){
        return quantity * basePrice;
    }

    var innerPriceRules = JSON.parse(JSON.stringify(priceRules));
    innerPriceRules.push({
        operator: 'greaterthan',
        pricePerUnit: basePrice,
        targetVariableOrProperty: 0
    });

    var orderedPriceRules = innerPriceRules.sort((a, b) => parseFloat(b.targetVariableOrProperty) - parseFloat(a.targetVariableOrProperty));

    var totalCost = 0;

    for (var currentQuantity = 1; currentQuantity <= quantity; currentQuantity++) {
        //find the price rule

        var priceRuleToApply = orderedPriceRules.filter(x => currentQuantity > x.targetVariableOrProperty)[0];

        totalCost += priceRuleToApply.pricePerUnit;
    }

    return totalCost;
}

export const removePaxMetaData = function(metaData) {
    metaData = metaData.filter(x => x.value != 'Type:PaxProduct');
    metaData = metaData.filter(x => x.value != 'Type:PaxSubscription');
    metaData = metaData.filter(x => !x.value.includes("TypeId:PaxProduct:"));
    metaData = metaData.filter(x => !x.value.includes("TypeId:PaxSubscription:"));

    return metaData;
}

export const convertProductSkeletonIntoProduct = async (courier, productSkeletonId) => {
    var baseProductSkeleton = await courier.ProductSkeletons.getById(productSkeletonId);
    var baseMetaData = await courier.ProductSkeletons.withId(productSkeletonId).MetaData.getData(1, 999, '');
    var basePriceRules = await courier.ProductSkeletons.withId(productSkeletonId).PriceRules.getData(1, 999, '');

    var product = JSON.parse(JSON.stringify(baseProductSkeleton));
    delete product.id;
    product.metaData = [];
    product.priceRules = [];

    if(baseMetaData && baseMetaData.payload && baseMetaData.payload.length){
        for(const nMetaData of baseMetaData.payload){
            product.metaData.push({
                value: nMetaData.value
            });
        }
    }

    if(basePriceRules && basePriceRules.payload && basePriceRules.payload.length > 0){
        for (const nPriceRule of basePriceRules.payload){
            product.priceRules.push({
                sourceProperty: nPriceRule.sourceProperty,
                operator: nPriceRule.operator,
                targetVariableOrProperty: nPriceRule.targetVariableOrProperty,
                pricePerUnit: nPriceRule.pricePerUnit
            });
        }
    }

    return product;
}