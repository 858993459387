<template>
  <v-dialog v-model="innerShow" :max-width="maxWidth" @click:outside="innerShow = false">
    <v-card>
      <v-card-title class="text-h5">
        <template v-if="!noItemToDelete">
          Are you sure you want to delete {{ itemToDelete }}?</template>
          <template v-else>
            Are sure you?
          </template>
      </v-card-title>
      <v-card-actions>
        <v-btn color="default" @click="clickedCancel">
          <template v-if="!noItemToDelete">
          No, Keep {{ itemToDelete }}</template>
          <template v-else>
            Cancel
          </template>
        </v-btn>

        <v-btn color="danger" class="white--text" @click="clickedDelete">
          <v-icon> mdi-delete-outline </v-icon>
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    noItemToDelete: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
    show: {
      type: Boolean,
      default: false,
    },
    itemToDelete: {
      type: String,
      default: "Big Bang Theory Because It Sucks",
    },
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        this.innerShow = this.show;
      },
    },
    innerShow: {
      handler() {
        this.$emit("update:show", this.innerShow);
      },
    },
  },
  methods: {
    clickedDelete() {
      this.innerShow = false;
      this.$emit("dialog-delete-deleted");

      this.$emit("delete");
    },
    clickedCancel() {
      this.innerShow = false;
      this.$emit('cancel');
      this.$emit("dialog-delete-cancelled");
    },
  },
  data() {
    return {
      innerShow: false,
    };
  },
};
</script>

<style>

</style>