import * as generalFunctions from '../../utilities/GeneralUtilities';

export default {
    state: {
        selectedCommissionYear: 0,
        selectedCommissionQuarter: 0,
    },
    getters: {
        getCommissionYear(state){
            return state.selectedCommissionYear;
        },
        getCommissionQuarter: state => state.selectedCommissionQuarter
    },
    mutations: {
        setCommissionYear(state, number){
            state.selectedCommissionYear = number;
        },
        setCommissionQuarter(state, number){
            state.selectedCommissionQuarter = number;
        }
    }
}