
import store from '../store/index';

export const makeGraphRequest = async function (endPointPath, additionalHeaders) {
    return new Promise((resolve, reject) => {
        fetch(getGraphApiEndPoint() + endPointPath, getOptions(additionalHeaders)).then(fetchResults => {
            if (fetchResults.status == 404) {
                reject('Not Found')
            } else {
                resolve(fetchResults);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

const getGraphHeaders = function (additionalHeaders) {
    var headers = new Headers();
    var bearer = "Bearer " + store.getters.getGraphAccessToken;

    headers.append('Authorization', bearer);

    headers = addHeaders(headers, additionalHeaders);

    return headers;
}

const addHeaders = function (baseHeaders, additionalHeaders) {
    if (additionalHeaders) {
        additionalHeaders.forEach(addHeader => {
            baseHeaders.append(addHeader[0], addHeader[1]);
        });
    }

    return baseHeaders;
}

const getOptions = function (additionalHeaders) {
    var options = {
        method: "GET",
        headers: getGraphHeaders(additionalHeaders)
    };

    return options;
}

const getGraphApiEndPoint = function () {
    return 'https://graph.microsoft.com/v1.0/';
}

