<template>
  <v-dialog
    :ref="ref"
    v-model="showModal"
    :return-value.sync="dateVar"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateVar"
        :label="dialogTitle"
        prepend-icon="mdi-calendar"
        readonly
        :required="isRequired"
        :rules="getRules"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dateVar" scrollable :no-title="hideTitle">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="showModal = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="saveDate"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isRequired: {
      type: Boolean,
      default: false
    },
    useRange: {
      type: Boolean,
      default: false
    },
    uniqueId: {
      type: String,
      default: "",
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
    },
    dialogTitle: {
      type: String,
      default: "My Cool Date",
    },
  },
  watch: {
    date: {
      immediate: true,
      handler(newValue, oldValue) {
        this.dateVar = this.date;
      },
    },
  },
  computed: {
    getRules() {
      if(this.isRequired){
        return [
          x => !!x || (this.dialogTitle + ' is required ')
        ]
      }else{
        return [];
      }
    }
  },
  methods: {
    saveDate() {
      this.$refs[this.ref].save(this.dateVar);

      this.$emit("update:date", this.dateVar);
    },
  },
  data() {
    return {
      showModal: false,
      dateVar: "",
      ref: this.uniqueId,
    };
  },
};
</script>

<style>
</style>