import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import themeConfig from './modules/themeConfig'
import authData from './modules/authData'
import verticalSidebar from './modules/verticalSidebar'
import userProfile from './modules/userProfile';
import systemData from './modules/systemData';
import xeroData from './modules/xeroData';
import userPreferences from './modules/userPreferences'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,    
    themeConfig,
    authData,
    verticalSidebar,
    userProfile,
    systemData,
    xeroData,
    userPreferences
  },
})
