/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import * as nav from '../data/navigation';

//anal beads

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/tv/',
      component: () => import('@/views/tv/TVBase.vue'),
      children: [
        {
          path: 'SupportAgents',
          name: 'SupportAgents',
          component: () => import('@/views/tv/support/SupportAgents.vue')
        }
      ]
    },
    {
      path: '/sessions/',
      component: () => import('@/views/sessions/base/SessionsBase.vue'),
      children: [
        {
          name: 'Error',
          path: 'error',
          component: () => import('@/views/sessions/Error.vue')
        }
      ]
    },
    {
      path: '/app/field',
      component: () => import('@/views/app/blank/BlankBase.vue'),
      children: [
        {
          path: 'ApprovalCreate',
          name: 'CreateApprovalExternal',
          component: () => import("@/views/app/blank/ApprovalCreate")
        },
      ]
    },
    {
      path: '/app/',
      redirect: '/app/pages/Blank'
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      redirect: "/app/pages/Blank",
      children: [
        {
          path: "/app/pages",
          component: () => import("@/views/app/pages/Pages"),
          children: [
            {
              path: 'mycommission',
              component: () => import("@/views/app/pages/commission/CommissionDashboard.vue"),
              name: 'My Commissions'
            },
            {
              path: 'addcommission/:commissionId?',
              component: () => import('@/views/app/pages/commission/CommissionAdd.vue'),
              name: 'Add Commission'
            },
            {
              path: 'viewcommission/:commissionId',
              component: () => import('@/views/app/pages/commission/CommissionView.vue'),
              name: 'View Commission'
            },
            {
              path: 'companycommissions',
              component: () => import('@/views/app/pages/commission/CompanyCommission.vue'),
              name: 'Company Commissions'
            },
            {
              path: 'groups',
              name: 'Groups',
              component: () => import('@/views/app/pages/Groups/GroupsList.vue')
            },
            {
              path: 'groups/:scopeGroupId',
              name: 'View Group',
              component: () => import('@/views/app/pages/Groups/GroupView.vue')
            },
            {
              path: 'scopes',
              name: 'Scopes',
              component: () => import('@/views/app/pages/Scopes/ScopesList.vue')
            },
            {
              path: 'microsoftproductreport',
              name: 'Microsoft Product Report',
              component: () => import('@/views/app/pages/Reports/MicrosoftProductReport.vue')
            },
            {
              path: 'completednoinvoicejob',
              name: 'Completed Not Invoiced Jobs',
              component: () => import('@/views/app/pages/Reports/CompletedNotInvoicedJobs.vue')
            },
            {
              path: 'opennoinvoicejob',
              name: 'Open Not Invoiced Jobs',
              component: () => import('@/views/app/pages/Reports/OpenNotInvoicedJobs.vue')
            },
            {
              path: 'globaljobs',
              name: 'Global Jobs',
              component: () => import('@/views/app/pages/Reports/GlobalJobs.vue')
            },
            {
              path: "blank",
              name: "blank",
              component: () => import("@/views/app/pages/Blank")
            },
            {
              name: 'Add Job',
              path: 'addjob/:companyId/:jobId?',
              component: () => import('@/views/app/pages/Jobs/JobAdd')
            },
            {
              name: 'Job View',
              path: 'viewjob/:jobId',
              component: () => import('@/views/app/pages/Jobs/JobView')
            },
            {
              name: 'List Companies',
              path: 'companies',
              component: () => import('@/views/app/pages/Companies/Companies')
            },
            {
              name: 'Company Dashboard',
              path: 'companies/:companyId',
              component: () => import('@/views/app/pages/Companies/CompanyView')
            },
            {
              name: 'Company Add',
              path: 'addcompany',
              component: () => import('@/views/app/pages/Companies/CompanyAdd')
            },
            {
              name: 'List Product Skeletons',
              path: 'productskeletons',
              component: () => import('@/views/app/pages/Products/ProductSkeleton/ProductSkeletons')
            }, {
              name: 'Add Product Skeleton',
              path: 'addproductskeleton',
              component: () => import('@/views/app/pages/Products/ProductSkeleton/ProductSkeletonsAdd')
            },
            {
              name: 'Product Skeleton View',
              path: 'viewproductskeleton/:productSkeletonId',
              component: () => import('@/views/app/pages/Products/ProductSkeleton/ProductSkeletonView')
            },
            {
              name: 'Product Skeleton Edit',
              path: 'editproductskeleton/:productSkeletonId',
              component: () => import('@/views/app/pages/Products/ProductSkeleton/ProductSkeletonEdit')
            },
            {
              name: 'Edit Global Product Skeleton',
              path: 'globalproductskeletonedit/:productSkeletonId',
              component: () => import('@/views/app/pages/Products/ProductSkeleton/ProductSkeletonGlobalEdit.vue')
            },
            {
              name: 'Testing Page',
              path: 'testing',
              component: () => import('@/views/app/pages/Dev/Testing')
            },
            {
              name: 'Cheat Sheet',
              path: 'cheatsheet',
              component: () => import('@/views/app/pages/Dev/CheatSheet')
            },
            {
              name: 'System Users',
              path: 'systemusers',
              component: () => import('@/views/app/pages/SystemUsers/SystemUserList.vue')
            },
            {
              name: 'Add System User',
              path: 'addsystemuser',
              component: () => import('@/views/app/pages/SystemUsers/SystemUserAdd.vue')
            },
            {
              name: 'View System User',
              path: 'viewsystemuser/:userId',
              component: () => import('@/views/app/pages/SystemUsers/SystemUserView.vue')
            },
            {
              name: 'Pax Sync Companies',
              path: 'paxcompanysync',
              component: () => import('@/views/app/pages/Dev/PaxCompanySync.vue')
            },
            {
              name: 'Zoho Sync Companies',
              path: 'zohocompanysync',
              component: () => import('@/views/app/pages/Dev/ZohoCompanySync.vue')
            },
            {
              name: 'Zoho Sync Contacts',
              path: 'zohocontactsync',
              component: () => import('@/views/app/pages/Dev/ZohoContactSync.vue')
            },
            {
              name: 'Data Export',
              path: 'export',
              component: () => import('@/views/app/pages/Dev/DataExport.vue')
            },
            {
              name: 'Token Management',
              path: 'tokenmanagement',
              component: () => import('@/views/app/pages/Dev/TokenCheatSheet.vue')
            },
            {
              name: 'Reports',
              path: 'reports',
              component: () => import('@/views/app/pages/Reports/Reports.vue')
            },
            {
              name: 'Report Add',
              path: 'reportadd',
              component: () => import('@/views/app/pages/Reports/ReportAdd.vue')
            },
            {
              name: 'Report View',
              path: 'reportview',
              component: () => import('@/views/app/pages/Reports/ReportView.vue')
            },
            {
              name: 'Support Time Usage',
              path: 'supporttimeusage',
              component: () => import('@/views/app/pages/Reports/SupportTimeReports.vue')
            },
            {
              name: 'Contact View',
              path: 'contactview/:contactId',
              component: () => import('@/views/app/pages/Contacts/ContactView.vue')
            },
            {
              name: 'Contract View',
              path: 'contractview/:contractId',
              component: () => import('@/views/app/pages/Contracts/ContractView.vue')
            },
            {
              name: 'Xero Settings',
              path: 'xerosettings',
              component: () => import('@/views/app/pages/Settings/XeroSettings.vue')
            }
          ]
        },
        {
          path: '/app/colin',
          component: () => import('@/views/app/pages/Pages'),
          children: [
            {
              path: 'companies',
              component: () => import('@/views/app/colin/Companies')
            }
          ]
        }
      ]
    },

  ]
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }




  next();
});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
