import * as generalFunctions from '../../utilities/GeneralUtilities';

export default {
    state: {
       versionNumber: ''
    },
    getters: {
        getVersionNumber: state => state.versionNumber,
    },
    mutations: {
       setVersionNumber(state, versionNumber){
           state.versionNumber = versionNumber;
       }
    }
}