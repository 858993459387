export default {
    state: {
      xeroProfiles: []
    },
    getters: {
      getXeroProfiles: state => state.xeroProfiles,
    },
    mutations: {
      setXeroProfiles(state, profiles) {
        state.xeroProfiles = profiles;
      }
    }
  };
  