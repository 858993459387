import * as generalFunctions from '../../utilities/GeneralUtilities';

export default {
    state: {
        name: '',
        firstName: '',
        lastName: '',
        profilePhoto: '',
        jobTitle: '',
        currentUserId: '',
        userScopes: [],
    },
    getters: {
        userPhoto: state => state.profilePhoto,
        loggedInUserInitials: state => (state.firstName ? state.firstName[0] : "U") + (state.lastName ? state.lastName[0] : "SR"),
        userJobRole: state => state.jobTitle,
        userFirstName: state => state.firstName,
        userLastName: state => state.lastName,
        userFullName: state => state.name,
        userId: state => state.currentUserId,
        hasScope: state => scope => {
            return state.userScopes.some(x => x === scope);
        }
    },
    mutations: {
        setUserId(state, userId){
            state.currentUserId = userId;
        },
        setUserPhoto(state, userPhoto) {
            state.profilePhoto = userPhoto;
        },
        setJobRole(state, jobRole){
            if(jobRole){
                state.jobTitle = jobRole;
            }else{
                state.jobTitle = 'Lackey';
            }            
        },
        setName(state, name){
            var nameObj = generalFunctions.getUserNameSeperated(name);

            state.name = name;
            state.firstName = nameObj.firstName;
            state.lastName = nameObj.lastName;
        },
        setUserScopes(state, allScopes){
            state.userScopes = allScopes;
        }
    }
}